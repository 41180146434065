import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LoadingSpinner from "../components/common/LoadingSpinner/LoadingSpinner";
import withRouter from "../hooks/withRouter";
import EventEmitter from "../utils/EventEmmiter";

/* loader component for Suspense*/
/* Used to render a lazy component with react-router */
// const waitFor = (Tag) => (props) => <Tag {...props} />;
const Login = lazy(() => import("../pages/login/login"));
const Principal = lazy(() => import("../pages/principal/principal"));
const Home = lazy(() => import("../pages/home/home"));
const ProductFamilyList = lazy(() => import("../pages/product-family/product-family-list"));
// const ProductFamily = lazy(() => import("../pages/product-family/product-family"));
const ProductList = lazy(() => import("../pages/product/product-list"));
const ServiceUnitList = lazy(() => import("../pages/service-unit/service-unit-list"));
const LabelTypesList = lazy(() => import("../pages/label-types/label-types-list"));
const OperationTypesList = lazy(() => import("../pages/operation-types/operation-types-list"));
const ServiceTypesList = lazy(() => import("../pages/service-type/service-type-list"));
const ServiceFeeList = lazy(() => import("../pages/service-fee/service-fee-list"));

const ReceptionByBuyList = lazy(() => import("../pages/reception-by-buy/reception-by-buy-list"));
const ReceptionByBuy = lazy(() => import("../pages/reception-by-buy/reception-by-buy"));
const ReceptionByReturnList = lazy(() => import("../pages/reception-by-return/reception-by-return-list"));
const ReceptionByReturn = lazy(() => import("../pages/reception-by-return/reception-by-return"));

const OrderPage = lazy(() => import("../pages/order/order"));
const OrderList = lazy(() => import("../pages/order/order-list"))
const InventoryAdjustment = lazy(() => import("../pages/inventory-adjustment/inventory-adjustment"));
const RearrangementPlatform = lazy(() => import("../pages/rearrangement-platform/rearrangement-platform"));
const QueryInventoryReport = lazy(() => import("../pages/query-inventory-report/query-inventory-report"));
const ServicesReport = lazy(() => import("../pages/services-report/services-report"));

const RequireAuth = ({ children }) => {
	let auth = (localStorage.getItem("token") || "") !== "";

	return auth ? children : <Navigate replace to="/login" />;
};

class ApplicationRoutes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			menu: []
			,currentKey: "/"
			,timeout: { enter: 500, exit: 500 }
			,animationName: "rag-fadeIn"
		}

		this.emitter = new EventEmitter();
	}

	componentDidMount() {
		this.emitter.subscribe("userMenu", this.setMenuData);
	}

	setMenuData = (mnu) => {
		this.setState({
			...this.state
			,menu: mnu
		});

		setTimeout(this.navigateToHistoryUrl, 200);
	}

	navigateToHistoryUrl = () => {
		// let curUrl = localStorage.getItem("curUrl") || "";
		// if(curUrl && curUrl.trim().length > 0 && this.hasMenuByKey(curUrl) ) {
		// 	this.props.router.navigate(curUrl);
		// }
	}

	hasMenuByKey = (mnuKey) => {
		return this.state.menu.filter(flt => flt.paginas.filter(fP => fP.rutaPagina === mnuKey).length > 0).length > 0;
	}

	render() {
		return (
			<TransitionGroup>
				<CSSTransition key={this.state.currentKey} timeout={this.state.timeout} classNames={this.state.animationName} exit={false}>
					<div>
						<Suspense fallback={<LoadingSpinner />}>
							<Routes>
								<Route index element={<Navigate replace to="/portal/home" />} />
	
								<Route path="/login" element={<Login />} />
								<Route path="/portal" element={<RequireAuth><Principal /></RequireAuth>}>
									<Route index element={<Home />} />
									<Route path="/portal/home" element={<Home />} />
									{
										this.hasMenuByKey("/portal/product-family") 
											? <Route path="/portal/product-family" element={<ProductFamilyList />} /> 
											: ""
									}

									{
										this.hasMenuByKey("/portal/product") 
											? <Route path="/portal/product" element={<ProductList />} /> 
											: ""
									}

									{
										this.hasMenuByKey("/portal/service-unit") 
											? <Route path="/portal/service-unit" element={<ServiceUnitList />} /> 
											: ""
									}

									{
										this.hasMenuByKey("/portal/label-types") 
											? <Route path="/portal/label-types" element={<LabelTypesList />} /> 
											: ""
									}

									{
										this.hasMenuByKey("/portal/operation-types") 
											? <Route path="/portal/operation-types" element={<OperationTypesList />} /> 
											: ""
									}

									{
										this.hasMenuByKey("/portal/service-types") 
											? <Route path="/portal/service-types" element={<ServiceTypesList />} /> 
											: ""
									}

									{
										this.hasMenuByKey("/portal/service-fee") 
											? <Route path="/portal/service-fee" element={<ServiceFeeList />} /> 
											: ""
									}


									{
										this.hasMenuByKey("/portal/receptionByBuy") 
											? <Route path="/portal/receptionByBuy" element={<ReceptionByBuyList />} />
											: ""
									}

									{
										this.hasMenuByKey("/portal/receptionByBuy") 
											? <Route path="/portal/receptionByBuy/:id" element={<ReceptionByBuy />} />
											: ""
									}

									{
										this.hasMenuByKey("/portal/receptionByReturn") 
											? <Route path="/portal/receptionByReturn" element={<ReceptionByReturnList />} />
											: ""
									}

									{
										this.hasMenuByKey("/portal/receptionByReturn") 
											? <Route path="/portal/receptionByReturn/:id" element={<ReceptionByReturn />} />
											: ""
									}

									{
										this.hasMenuByKey("/portal/order") 
											? <Route path="/portal/order" element={<OrderList />} />	
											: ""
									}

									{
										this.hasMenuByKey("/portal/order") 
											? <Route path="/portal/order/:id" element={<OrderPage />} />	
											: ""
									}

									{
										this.hasMenuByKey("/portal/inventory-adjustment") 
											? <Route path="/portal/inventory-adjustment" element={<InventoryAdjustment />} />	
											: ""
									}

									{
										this.hasMenuByKey("/portal/rearrangement-platform") 
											? <Route path="/portal/rearrangement-platform" element={<RearrangementPlatform />} />	
											: ""
									}						
								</Route>

								<Route path="/reportes" element={<RequireAuth><Principal /></RequireAuth>}>
									<Route index element={<Home />} />
									<Route path="/reportes/home" element={<Home />} />
									{
										this.hasMenuByKey("/reportes/query-inventory") 
											? <Route path="/reportes/query-inventory" element={<QueryInventoryReport />} /> 
											: ""
									}

									{
										this.hasMenuByKey("/reportes/services-report") 
											? <Route path="/reportes/services-report" element={<ServicesReport />} />	
											: ""
									}
								</Route>
								<Route path="*" element={<Navigate replace to="/portal/home" />} />
							</Routes>
						</Suspense>
					</div>
				</CSSTransition>
			</TransitionGroup>
		);
	}

}

export default withRouter(ApplicationRoutes);
// export default ApplicationRoutes;