import React from "react";
import { BrowserRouter } from 'react-router-dom';
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ApplicationRoutes from "./routes/routes";
import LoadingSpinner from "./components/common/LoadingSpinner/LoadingSpinner";
import EventEmitter from "./utils/EventEmmiter";

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showLoading: false
		}

		this.showSppiner = this.showSppiner.bind(this);
		this.emitter = new EventEmitter();
	}

	componentDidMount() {
		this.emitter.subscribe("Loading", this.showSppiner);
	}


	showSppiner = (isLoading) => {
		this.setState({showLoading: isLoading});
	}

	render() {
		
	  // specify base href from env varible 'PUBLIC_URL'
	  // use only if application isn't served from the root
	  // for development it is forced to root only
	  /* global PUBLIC_URL */
	  //const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
	  const basename = "/";
	  
	  return (
		  <BrowserRouter basename={basename}>
			{this.state.showLoading ? <LoadingSpinner /> : null }
			<ApplicationRoutes /> 
		  </BrowserRouter>
	  );
  
	}
  }
  
  export default App;