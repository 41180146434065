//this is an estatatic global variable for this EventEmitter class
let events = {};

export default class EventEmitter {
    subscribe(event, callback) {
        (events[event] || (events[event] = [])).push(callback);

        return {
            unsubscribe: () => {
                events[event] && events[event].splice(events[event].indexOf(callback), 1);
            }
        };
    }
  

    emit(event, data) {
        (events[event] || []).forEach(fn => fn(data));
    }
  }