import React from "react";
import "./LoadingSpinner.css";

export default function LoadingSpinner() {
	return (
		<div className="spinner-container">
			{/* <div className="loading-spinner"></div> */}
			<div className="spinner-border text-primary" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
}
